<template>
  <div id="app" class="crt">
    <div style="display:flex;margin:10px 0">
      <div class="item">
        <el-input v-model="crtQuery.name" placeholder="请输入crt名称" clearable></el-input>
      </div>
      <!-- <div class="item">
        <el-select v-model="crtQuery.ciId" filterable  placeholder="请选择公司名称">
          <el-option v-for="item in companyNameList" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </div> -->
      <div class="item">
          <el-cascader 
             placeholder="请选择公司名称"
             filterable v-model="crtQuery.ciId" 
             :show-all-levels="false" 
             :options="allCompanyList" 
             :props="defaultProps" 
             @change="searchDeviceCompanyInfo($event)" 
             ref="cascader1"
             clearable
            >
          </el-cascader>
      </div>
      <div class="item">
        <el-button type="primary" @click="getCrtList">查询</el-button>
      </div>
      <div class="item">
        <el-button type="primary"  @click="restList">重置</el-button>
      </div>
      <div class="item" v-if="userRightList.addJuris=='1'">
        <el-button type="success" size="default" @click="addImgInfo" >添加 CRT 图片</el-button>
      </div>
    </div>
          
    <div>
      <el-table v-loading="loading" :data="crtList" :stripe="true" :border="true" style="width: 100%" :max-height="700" highlight-current-row>
          <el-table-column type="index" label="序 号" align="center" width="100"></el-table-column>
          <el-table-column prop="name" label="CRT名称" align="center"></el-table-column>
          <el-table-column label="图片" align="center">
            <template slot-scope="scope">
              <!-- 　<img :src="baseUrl + scope.row.url" width="80" style="margin:0 auto" /> -->
              <el-image style="width: 80px" :src="baseUrl + scope.row.url" :preview-src-list="scope.row.imgList">
              </el-image>
            </template>
          </el-table-column>

          <el-table-column prop="ciId" label="公司" align="center"></el-table-column>
          <el-table-column prop="sort" label="排列顺序" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="150" v-if="userRightList.updateJuris=='1'">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="primary" size="small" v-if="userRightList.updateJuris=='1'">编辑</el-button>
              <el-button @click="removeCrtInfo(scope.row.id)" type="danger" size="small" v-if="userRightList.delJuris=='1'">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
    </div>

    <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="getCrtList"></el-pagination>

    <el-dialog title="CRT信息" :visible.sync="dialogVisible" width="40%" @close="clearCrtInfo" style="background:rgba(0,0,0,0.5)">
      <el-form label-width="120px" :model="crtInfo" style="height:400px;overflow:scroll;scrollbar-width:none">
        <el-form-item label="CRT名称">
          <el-input v-model="crtInfo.name" placeholder="请输入CRT名称" style="width:90%" />
        </el-form-item>
        <!-- 
        <el-form-item label="当前公司" v-if="showEdit">
          <el-tag type="success">{{editCompany}}</el-tag>
        </el-form-item> -->

        <el-form-item label="公司名称">
          <el-cascader 
          :placeholder="editCompany" 
          :show-all-levels="false" 
          :value="crtInfo.ciId" 
          clearable 
          ref="cascader" 
          filterable 
          :options="allCompanyList" 
          @change="checkedCompany" 
          :props="defaultProps" 
          style="width:90%">
          </el-cascader>
        </el-form-item>

        <el-form-item label="排序顺序">
          <el-input v-model="sortNum" placeholder="请输入排序顺序" type="number" style="width:90%"></el-input>
        </el-form-item>

        <el-form-item label="CRT图片" prop="val">
          <input id="crtInfoPicture" type="file" ref="uploadInput" @change="fileChange" />
          <span v-if="addOrEditFlag == 'add'">上传图标只能是 JPG、PNG或SVG 格式,并且大小不超过100M</span>
          <img v-if="imgFlag" :src="crtInfo.url" style="width: 300px" />
          <img v-if="addOrEditFlag == 'edit' && !imgFlag" :src="baseUrl + crtInfo.url" style="width: 300px" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditCrtInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CRT from "@/api/manageApi/CrtInfo.js";
import BaseData from "@/assets/config/BaseData.js";
import companySystem from "@/api/managementApi/CompanyInfo";
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
// vuex
import { mapState} from 'vuex'
export default {
  data() {
    return {
      showEdit: false,
      companyNameById: {},
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },
      defaultProps11: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },
      companyName:"",
      companyNameList: [],// 查询公司名称下拉框数据
      //分页相关数据
      current: 1, //当前页
      limit: 10, //每页显示条数
      total: 0, //数据总数

      //页面数据
      crtList: [], //CRT集合

      crtInfo: {
        //当前编辑或添加的CRT信息
        id: "",
        name: "",
        url: "",
        ciId: "",
        cort: 0,
      },
      sortNum: '',
      crtQuery: {
        //模糊查询条件对象
        name: "",
        ciId: '',
      },
      //   编辑框里的公司名
      editCompany: '',

      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,

      //图片上传
      file: {},

      //标志位
      loading: true,
      dialogVisible: false, //添加、编辑弹框的标志位
      dialogImageVisible: false, //图片预览
      addOrEditFlag: "add", //添加、编辑标志位 add添加  edit编辑
      imgFlag: false,

      userRightList: [],


    };
  },
  created() {
    this.getUsers()

    this.getCompanyInfo();
    this.getAllcompanyNameList();
  },
   computed: {
    ...mapState({'allCompanyList':state=>state.companyInfo.allCompanyList})
  },
  methods: {
        // 根据公司名称查询 
    searchDeviceCompanyInfo(val) {
      let ciid ,a;
      if (val) {
         if (val.length <= 1) [ciid] = val 
          else [{...a},ciid] = val

          this.crtQuery.ciId = ciid
          this.$refs.cascader1.dropDownVisible = false;
      }
    },
    //重置按钮的单击事件方法
    async restList() {
      //点击了重置按钮之后，将查询的条件进行只看
      this.crtQuery.name = "";
      this.crtQuery.ciId = "";
      this.getUsers()
      this.getCompanyInfo();
      this.getAllcompanyNameList();
    },

    reset(){
      this.crtQuery.name = ''
      this.crtQuery.ciId = ''
    },
    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "CRTManagement") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },

    addImgInfo() {
      this.dialogVisible = true;
      this.showEdit = false;
    },
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        this.init();
      });
    },
    // 选择的公司
    checkedCompany() {
      // 点击获取公司ID
      this.crtInfo.ciId = this.$refs.cascader.getCheckedNodes()[0].data.id;
      console.log(this.$refs.cascader.getCheckedNodes()[0].data.name);
      // // 选择后让选择框消失
      this.$refs.cascader.dropDownVisible = false;
    },
    checkedCompany11() {
      console.log(this.$refs.cascader11.getCheckedNodes()[0]);
      let id = this.$refs.cascader11.getCheckedNodes()[0].data.id
      console.log(id);
      this.crtQuery.ciId = id
      // 点击获取公司ID
      // for (let i = 0; i < this.allCompanyList.length; i++) {
      //   if (this.$refs.cascader11.getCheckedNodes()[0].data.id == this.allCompanyList[i].name) {
      //     this.crtQuery.ciId = this.allCompanyList[i].id
      //   }
      // }
      // this.$refs.cascader.getCheckedNodes()[0].data.ciId;
      // this.crtQuery.ciId =

      console.log(this.crtQuery);
      // // 选择后让选择框消失
      this.$refs.cascader11.dropDownVisible = false;
    },

    init() {
      this.getCrtList();
    },
    goSelect() {
      this.$router.push("/Select");
    },
    //添加或者编辑CRT信息
    addOrEditCrtInfo() {
      if (!this.crtInfo.id) {
        //校验图片是否符合规范
        // this.file;
        // var aaa = this.beforeAvatarUpload(this.file) == true;

        if (this.crtInfo.name == "") {
          //提示信息
          this.$message({
            type: "warning",
            message: "CRT名称不能为空！",
          });
        } else if (JSON.stringify(this.file) == {}) {
          //提示信息
          console.log(JSON.stringify(this.file));
          this.$message({
            type: "warning",
            message: "请选择图片！",
          });
        } else if (this.beforeAvatarUpload(this.file) != true) {
          this.$message({
            type: "warning",
            message: "图片格式错误！",
          });
        } else {
          //实例化FormData对象用于传输图片文件和图片名称
          const formData = new FormData();
          formData.append("file", this.file);
          formData.append("sort", this.sortNum);
          formData.append("name", this.crtInfo.name);
          formData.append("ciId", this.crtInfo.ciId);

          //调用接口进行添加CRT信息
          CRT.addCrtInfo(formData).then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
            //回到页面并刷新
            this.getCrtList();
            if (res.data.success) {
              this.dialogVisible = false;
            }
          });
        }
      } else {
        if (this.sortNum == '') {
          this.sortNum = '999'
        }
        console.log(this.sortNum);


        //实例化FormData对象用于传输图片文件和图片名称
        for (let i = 0; i < this.allCompanyList.length; i++) {
          if (this.crtInfo.ciId == this.allCompanyList[i].name) {
            this.crtInfo.ciId = this.allCompanyList[i].id
          }
        }
        console.log(this.crtInfo.ciId);


        const formData = new FormData();
        formData.append("id", this.crtInfo.id);
        formData.append("crtName", this.crtInfo.name);
        formData.append("sort", this.sortNum);
        formData.append("ciId", this.crtInfo.ciId);
        formData.append("crtPicture", this.file);

        CRT.updateCrtInfo(formData).then((res) => {
          console.log(res);
          //提示信息
          // this.$message({
          //   type: res.data.success ? "success" : "warning",
          //   message: res.data.message,
          // });
          if (res.data.message == "修改成功！") {
            this.$message.success("修改成功！")
            this.getCrtList();
            this.dialogVisible = false;
          }
        });
      }
    },
    //编辑弹框
    handleEdit(row) {
      console.log(row);
      this.showEdit = true;
      this.editCompany = row.ciId
      this.crtInfo = {
        id: row.id,
        name: row.name,
        url: row.url,
        ciId: row.ciId,
      };
      this.sortNum = row.sort
      console.log(this.crtInfo);
      this.addOrEditFlag = "edit";
      this.dialogVisible = true;
    },
    //多条件分页查询CRT信息
    getCrtList() {
      console.log("公司的id" + this.crtInfo.ciId)
      console.log("公司的信息",this.crtQuery);
      console.log("baseUrl",this.baseUrl);
      CRT.getCrtList(this.current, this.limit, this.crtQuery).then((res) => {
        this.total = res.data.data.total;
        this.crtList = res.data.data.rows;
        for (let i = 0; i < this.crtList.length; i++) {
          let arr = []
          arr.push(this.baseUrl + this.crtList[i].url)
          this.crtList[i].imgList = arr
        }
        console.log(this.crtList);
        for (let i = 0; i < this.crtList.length; i++) {
          this.crtList[i].ciId = this.companyNameById[this.crtList[i].ciId];
        }
        if (res.data.success) {
          this.loading = false;
        }
      });
    },
    //删除CRT信息
    removeCrtInfo(id) {
      console.log(id);
      this.$confirm(
        "此操作将永久删除该CRT以及该CRT绑定的点位, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        //点击确定，指定then方法
        CRT.removeCrtInfo(id).then((res) => {
          console.log(res);
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getCrtList();
        });
      });
    },
    //获取所有公司名称
    getAllcompanyNameList() {
      companySystem.getCompanyInfo().then((res) => {
        this.companyNameList = res.data.data.list;
        console.log("后端接口中拿到的所有公司名称,",this.companyNameList);
      })
    },
    //清空设备类型信息对象
    clearCrtInfo() {
      this.crtInfo = {
        id: "",
        name: "",
        url: "",
        ciId: "",
      };
      this.addOrEditFlag = "add";
      this.getCrtList();
      this.imgFlag = false;
    },
    //返回上一级
    goBack() {
      window.history.back();
    },
    /****************************上传图片相关*************************************/
    //上傳设备类型图标文件选择后触发
    fileChange(e) {
      let files = e.target.files[0]; // 获取上传内容
      this.file = files;

      let fileReader = new FileReader();
      let type = "default";

      this.beforeAvatarUpload(files);
      //判断文件类型
      if (/image/.test(files.type)) {
        fileReader.readAsDataURL(files);
        type = "image";
      } else {
        fileReader.readAsText(files, "utf-8");
        type = "text";
      }
      //文件加载出错
      fileReader.onerror = function (err) { };
      fileReader.onload = () => {
        this.crtInfo.url = fileReader.result;
        this.imgFlag = true;
      };
    },
    //验证
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isSVG = file.type === "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 100;

      if (!(isJPG || isPNG || isSVG || isJPEG)) {
        this.file = {};
        document.getElementById("crtInfoPicture").value = "";
        this.$message.error("上传图标只能是 JPG、PNG或SVG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图标大小不能超过 100MB!");
        return (isJPG || isPNG || isSVG) && isLt2M;
      }

      return (isJPG || isPNG || isSVG || isJPEG) && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.crt .hide .el-upload--picture-card {
  display: none;
}

/deep/ .el-page-header__content {
  color: white;
}
</style>
